.kapperstats-container{
    padding: 10px;
}

.scroll-to-top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(9px);
    border: 0px solid #ddd;
    color: black;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    z-index: 10;
    transition: opacity 0.3s ease, transform 0.3s ease;
    backdrop-filter: blur(10px);
    /* Blur the background behind the button */
    -webkit-backdrop-filter: blur(5px);
    /* For Safari support */
}
