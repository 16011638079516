.discord-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* Allow children to stretch the full width */
  padding: 15px;
}

.discord-header {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
  /* Centers text horizontally */
  font-weight: 600;
}

.discord-icon {
  margin-right: 3px;
  color: white;
}

.discord-status {
  color: #555;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align left for status text */
}

.discord-button {
  width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white;
    background: linear-gradient(135deg,
        #6874fc,
        #4655ff,
        #3747f8,
        #6874fc,
        #4655ff,
        #3747f8);
    background-size: 400% 400%;
    animation: shimmerEffect 30s infinite linear;
    transition: all 0.3s ease-in-out;
}

.discord-actions {
  width: 100%;
  /* Ensure the button's parent stretches the full width */
}
