   .feed-post-card{
    border: 0px solid #f1f1f1;
    border-radius: 6px;
    padding: 10px;
    background-color: #fff;
    position: relative;
    margin-bottom: 10px;
    transition: transform 0.2s ease;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
   }

/* Odds Section */
.feed-post-odds {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    position: absolute;
    top: 0px;
    right: 10px;
}

.feed-post-top {
    display: flex;
    justify-content: space-between;
    /* Ensures date and odds stay on the same line */
    align-items: center;
    /* Aligns text vertically */
    gap: 8px;
    /* Adds spacing between date and odds */
}

.swipeable-wrapper {
    width: 100%;
    height: 100%;
    touch-action: pan-y;
    /* Prevent conflicts with vertical scrolling */
    position: relative;
    /* Ensure it aligns with the card */
    z-index: 1;
    /* Ensure swipeable area is above other elements */
}


.feed-post-card:hover {
    transform: scale(1.01);
}

.feed-post-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.feed-profile-picture {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.feed-post-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feed-post-kapper {
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.feed-post-date {
    font-size: 1rem;
    color: #555;
    font-weight: normal;
}



/* Units and League Section */
.feed-post-units-league-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 5px;
}

.feed-post-league,
.feed-post-units {
    font-size: 1rem;
        background-color: #f1f1f1;
        padding: 4px;
        border-radius: 6px;
        white-space: nowrap;
        font-weight: 600;
}

/* Content Section */
.feed-post-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    
}

.feed-post-analysis{
    font-size: 1rem;
        word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: normal;
            color: #555;
            border: 1px solid #f1f1f1;
            padding: 5px;
            padding-top: 3px;
            margin-bottom: 5px;
            margin-top: 10px;
            border-radius: 4px;
}

.feed-bet-legs {
    font-size: 1rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        margin-top: -5px;
        color: black;
        /* Set a reasonable minimum height */
        height: auto;
        /* Auto height */
        resize: vertical;
        /* Allow vertical resizing */
        margin-bottom: 5px;
}

.bet-leg-item {
    line-height: 1.4;
}
.feed-post-image {
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    padding: 0px;
    margin-bottom: -5px;
}

.blurred-content {
    filter: blur(5px);
    opacity: 0.9;
    pointer-events: none;
    user-select: none;
}

.blur-overlay {
    position: absolute;
    bottom: -30px;
    left: 70px;
    transform: translate(-50%, -50%);
    background: transparent;
    /* Optional: Darker background for readability */
    color: #555555a2;
    padding: 12px 6px;
    /* Increase horizontal padding */
    border-radius: 8px;
    font-size: .9rem;
    /* Slightly larger text */
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    white-space: nowrap;
    /* Prevents text from wrapping */
    min-width: 250px;
    /* Ensures it's wide enough */
    max-width: 90%;
    /* Prevents it from being too wide on smaller screens */
    text-align: center;
    z-index: 2;
}
.lock-icon {
    font-size: .9rem;
}

@keyframes pulseBorder {
    0% {
        box-shadow: 0 0 6px rgba(88, 101, 242, 0.7);
    }

    50% {
        box-shadow: 0 0 12px rgba(88, 101, 242, 1);
    }

    100% {
        box-shadow: 0 0 6px rgba(88, 101, 242, 0.7);
    }
}