
.stats-header {
    margin-bottom: 5px;
        font-size: 1.8rem;
        text-align: left;
        color: black;
}

.stats-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
        font-size: 1.1rem;
            word-wrap: break-word;
            overflow-wrap: break-word;
            white-space: normal;
            margin-top: -5px;
            color: #555;
            min-height: 20px;
            /* Set a reasonable minimum height */
            height: auto;
            /* Auto height */
            resize: vertical;
            /* Allow vertical resizing */
}

.stat-label {
    font-weight: normal;
}

.stat-value {
    font-weight: normal;
}

.daily-avg-label {
    font-weight: 600;
    color: black;
}

.daily-avg-value {
    font-weight: 600;
    color: black;
}

.toggle-buttons {
    display: flex;
    gap: 5px;
}

.toggle-btn {
    background: #f1f1f1;
    border: 0px solid #ccc;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 12px;
}

.toggle-btn.active {
    color: white;
    border-color: #5865F2;
}

.loading-text {
    text-align: center;
    font-size: 14px;
    color: gray;
}

.error-text {
    text-align: center;
    color: red;
    font-weight: bold;
}

.no-data-text {
    text-align: center;
    color: gray;
}

.kapper-stats-card{
        border-radius: 6px;
        padding: 0;
        /* Remove padding to allow banner to extend to edges */
        border: 0px solid #f1f1f1;
        margin-bottom: 10px;
        transition: transform 0.2s ease;
        margin-left: -10px;
        width: calc(100% + 20px);
        margin-top: -5px;
}

.stats-period-label{
    display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.1rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        margin-top: -5px;
        margin-bottom: 0px;
        color: black;
        font-weight: 600;
        min-height: 20px;
        /* Set a reasonable minimum height */
        height: auto;
        /* Auto height */
        resize: vertical;
        /* Allow vertical resizing */
}