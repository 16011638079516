.profile-page{
    padding: 10px;
}

.scroll-to-top-button{
margin-bottom: 65px;

}

.profile-page-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 10px 0;
    margin-top: 20px;
}

.account-container{
    padding: 15px;
}