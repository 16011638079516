.bankroll-input-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}

.bankroll-header {
    font-size: 1.2em;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
}

.bankroll-value {
    font-weight: 600;
}

.bankroll-edit-icon {
    cursor: pointer;
    font-size: 1.2em;
    /* Matches other Pen icons */
    color: black;
    /* Matches text color */
    margin-left: 10px;
    transition: color 0.3s ease;
    margin-right: 10px;
}

.bankroll-edit-buttons {
    display: flex;
    gap: 10px;
}

.bankroll-input {
    width: auto;
    padding: 5px 10px;
    border: 1px solid #ddd;
    color: #555;
    border-radius: 3px;
    margin-left: 5px;
    box-sizing: border-box;
    transition: border-color 0.2s;
}

.bankroll-input:focus {
    border-color: #ddd;
    outline: none;
}

.bankroll-edit-note {
    font-size: 0.85em;
    color: #555;
    padding: 10px 20px;
}

.bankroll-edit-note input[type='checkbox'] {
    color: #5865F2;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.unit-converter-header{
font-size: 1rem;
margin-left: 5px;
}