   .feed-alert-card {
       border: 2px solid #4655ff;
       border-radius: 6px;
       padding: 10px;
       background-color: #fff;
       position: relative;
       margin-bottom: 10px;
       transition: transform 0.2s ease;
       word-wrap: break-word;
       overflow-wrap: break-word;
       white-space: normal;
       box-shadow: 0px 0px 3px #4655ff;
   }

   .feed-alert-card:hover {
       transform: scale(1.01);
   }

.dismiss-alert-button {
    background: transparent;
    border: none;
    font-size: 1.5em;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    color: black;
    right: 10px;
    top: 0px;
    padding: 0px;
}

.feed-alert-image {
    width: 100%;
    margin-top: -30px;
    object-fit: cover;
    border-radius: 6px;
}

.feed-post-title {
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
    margin-top: 10px;
}

.alert-body{
    font-size: 1rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        margin-top: -5px;
        color: black;
        min-height: 20px;
        /* Set a reasonable minimum height */
        height: auto;
        /* Auto height */
        resize: vertical;
        /* Allow vertical resizing */
}

.feed-alert-content {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    padding-top: 10px;
}