

/* ✅ Create Post Overlay (Matches Sidebar Overlay Behavior) */
.create-post-overlay {
  position: fixed;
  bottom: -400px;
  left: 0;
  width: 100%;
  height: calc(100% - 400px);
  /* Matches the height of the post container */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(2px);
  z-index: 50;
  opacity: 0;
  transform: translateY(-100%);
  /* Starts fully out of view */
  transition: bottom 0.4s ease, opacity 0.8s ease;
}



/* ✅ When Post Menu Opens, Move Overlay Into View */
.create-post-container.open~.create-post-overlay {
  bottom: 400px;
  opacity: 1;
  pointer-events: auto;
}


/* ✅ Create Post Menu (Slides Up) */
.create-post-container {
  position: fixed;
  bottom: -200%;
  /* Start completely out of view */
  left: 0;
  width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 1),rgba(255, 255, 255, 1),rgba(255, 255, 255, 1), rgba(250,
      250,
      250,
      0.9));
  backdrop-filter: blur(9px);
  transition: bottom 0.4s ease;
  z-index: 1000;
  padding: 15px;
  border-top: 0px solid #ddd;
  border-radius: 8px;
  min-height: 540px;
  max-height: 600px;

}

/* ✅ When Open, Move Up */
.create-post-container.open {
  bottom: 60px;
  pointer-events: auto;
  /* Blocks interactions behind the overlay */
  /* Slides up to fully visible */
}

.create-post-content {
  font-size: 1rem;
  color: black;
  padding: 2px;
  font-weight: 600;
  /* Adjust based on header/footer height */
  overflow-y: auto;
  /* Enable scrolling */
  padding-top: 10px;
  box-sizing: border-box;
  /* Ensure padding doesn’t affect height */
  padding-bottom: 100px;
  
}

/* ✅ Hide default scrollbar on WebKit browsers (Optional) */
.create-post-content::-webkit-scrollbar {
  width: 0px;
}

.post-buttons-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  /* Ensure it stays at the bottom */
  width: 100%;
  gap: 5px;
  padding-bottom: 25px;
  pointer-events: none;
  padding-left: 15px;
  /* Allows buttons underneath to be clickable */
}

/* ✅ Ensure buttons are still interactive */
.post-buttons-container button {
  pointer-events: auto;
  /* Restores clickability for buttons */
}
/* ✅ Odds Textbox */
.odds-textbox-group {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: normal;
}

.odds-textbox {
  padding: 5px;
  font-size: 1.5rem;
  color: black;
  font-weight: 600;
border: 2px solid #f1f1f1;
  background-color: transparent;
  border-radius: 6px;
  width: 100px;
  text-align: left;
  box-sizing: border-box;
  margin: 10px;
  margin-right: 0px;
}

/* ✅ Slider Styling */
.slider-container {
  margin-top: 20px;
  margin-bottom: 30px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% - 10px);
  height: 6px;
  background: #f7f7f7;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 3px black;
  border: 3px solid var(--thumb-color, black);
}

/* ✅ League Buttons */
.create-post-league-container {
  margin-bottom: 10px;
}

.create-post-league-row {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 6px;
  gap: 6px;
}

.create-post-league-row::-webkit-scrollbar {
  display: none;
}

.create-post-league-button {
  flex-shrink: 0;
  border: 0;
  border: 0px solid #f1f1f1;
  background-color: #f7f7f7;
  color: #555;
  border-radius: 6px;
  margin: 1px;
  padding: 8px;
  cursor: pointer;
  font-weight: normal;
  transition: background-color 0.3s, border-color 0.3s;
}
.create-post-league-button.selected {
  background: #d5d5d5;
  color: black;
  font-weight: 600;
}
/* ✅ Image Upload */
.upload-image-container {
  height: 200px;
  border-radius: 6px;
  text-align: center;
border: 0px solid #f1f1f1;
  background-color: #f7f7f7;

}

.upload-label {
  cursor: pointer;
}

.upload-icon {
  font-size: 1.5rem;
  color: #a9a9a9;
  padding-top: 40px;
}

.upload-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #a9a9a9;
  margin-top: 10px;
  margin-bottom: 30px;
}

.upload-input {
  display: none;
}

.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 200px;
  /* Ensures it doesn't take up the full screen */
  overflow-y: auto;
  /* Enables vertical scrolling */
  position: relative;
  border-radius: 6px;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 6px;
}

.clear-image-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(9px);
  color: black;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 15px;
  border: none;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* ✅ Alert Messages */
.alert-danger {
  color: rgb(238, 0, 0);
  text-align: center;
  margin-top: 5px;
}

.alert-info {
  color: rgba(43, 177, 43);
  text-align: center;
  margin-top: 5px;
}

.disclaimer {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 14px;
  color: #555;
  width: 100%;
}

.back-button {
  width: 50px;
  /* Takes up 15% of the width */
  height: 50px;
  /* Same height as the next button */
  border-radius: 50%;
  /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
    background: #f1f1f1;
      background-size: 999% 999%;
      animation: gradientAnimation 30s ease-in-out infinite;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

.create-post-modal-button {
  width: calc(100% - 70px);
  height: 50px;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white;
    margin-bottom: 8px;
    background: linear-gradient(135deg,
        #292929 0%,
        #292929 30%,
        #6c6c6c 50%,
        /* Soft highlight */
        #292929 70%,
        #292929 100%);
    background-size: 400% 400%;
    animation: shimmerEffect 15s infinite linear;
    transition: all 0.1s ease-in-out;
}

.next-button {
  position: absolute;
  bottom: 25px;
  left: 0%;
  width: calc(100% - 30px);
  height: 50px;
  margin: 15px;
  cursor: pointer;
      padding: 10px;
      font-size: 1.2rem;
      font-weight: 600;
      border-radius: 50px;
      cursor: pointer;
      border: none;
      color: white;
      margin-bottom: 8px;
      background: linear-gradient(135deg,
          #292929 0%,
          #292929 30%,
          #6c6c6c 50%,
          /* Soft highlight */
          #292929 70%,
          #292929 100%);
      background-size: 400% 400%;
      animation: shimmerEffect 15s infinite linear;
      transition: all 0.1s ease-in-out;
}

.create-post-open-button {
  position: relative;
  width: 100%;
  border-bottom: 4px solid #5865F2;
  color: black;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  margin-top: -5px;
}

.create-post-button-text {
  font-size: 2rem;
  font-weight: 600;
}

.create-post-button-icon {
  font-size: 2rem;
  color: #555;
}

.character-counter {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
  cursor: pointer;
  text-align: right;
}

.note-toggle {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
  cursor: pointer;
  font-weight: normal;
}

.drop-note-toggle {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
  cursor: pointer;
  margin-top: -33px;
  font-weight: normal;
}

.analysis-textarea {
  padding: 5px;
  font-size: 1.1rem;
  color: black;
  line-height: 1.5;
  width: calc(100%);
  border-radius: 6px;
  box-sizing: border-box;
  border: 2px solid #f1f1f1;
  resize: none;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: -10px;
}

body.no-scroll {
  overflow: hidden;
  touch-action: none;
}

.units-header {
  flex-shrink: 0;
    border: 0;
    background-color: transparent;
    color: black;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    font-size: 1.2rem;
    margin-bottom: 5px;
}

/* Toggle Button Styling */
.betting-mode-toggle {
  display: flex;
  justify-content: left;
  gap: 5px;
  margin-bottom: 10px;
  border-bottom: 2px solid #f7f7f7;
  padding-left:0px;
}

.mode-button {
  flex-shrink: 0;
    border: 0;
    background-color: transparent;
    color: #555;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
      font-size: 1.2rem;
    padding-left: 0px;
}

.mode-button.active {
  background: transparent;
    border: 0px solid #f7f7f7;
    color: black;
    font-weight: 600;
}

/* Slider Styling */
.kelly-slider-group {
  margin-top: 10px;
}

.post-type-toggle {
  display: flex;
  justify-content: left;
  gap: 5px;
  margin-bottom: 10px;
    border-bottom: 2px solid #f7f7f7;
  padding-left: 0px;
}

.text-post-container {
  display: flex;
  flex-direction: column;
}

.title-input {
  font-size: 1.5rem;
    color: black;
    font-weight: normal;
    padding: 5px;
    border-radius: 6px;
    width: 100%;
    margin-top: 10px;
    border: 2px solid #f1f1f1;
}

.legs-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.leg-input-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.leg-input {
  font-size: 1rem;
    color: black;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    border-radius: 6px;
    width: 100%;
    padding: 5px;
    border: 2px solid #f1f1f1;
}

.remove-leg-button {
  background: transparent;
  color: #555;
  font-weight: normal;
  border: none;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
}

.add-leg-button {
  background: transparent;
  color: #555;
  border: none;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
}

