/* General Styles */
.feed-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  
}

.feed-loading,
.feed-error {
  text-align: center;
  font-size: 1.2em;
  color: #4a4a4a;
  margin-top: 50px;
    margin-bottom: 50px;
}

.feed-empty {
    text-align: center;
      font-size: 1.7rem;
      color: #fcfcfc;
      font-weight: 600;
      padding-top: 25px;
      padding-bottom: 30px;
      /* Replace with the path to your image */
      background-size: cover;
      /* Ensure the image covers the entire container */
      background-repeat: no-repeat;
      /* Prevent image repetition */
      border-radius: 12px;
          background: linear-gradient(135deg,
                #dcdbdb,
                #dfdfdf,
                #bcbcbc,
                  #dfdfdf,
                  #dcdbdb);
            background-size: 999% 999%;
            animation: gradientAnimation 30s ease-in-out infinite;
            transition: transform 0.2s ease;
}

.bed-icon{
  color: #555;
}

.feed-empty:hover {
  transform: scale(1.01);
  /* Slight zoom effect on hover */
}

.feed-post-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px 0;
}

.divider-line {
  flex-grow: 1;
  border: none;
  border-top: 2px solid #a9a9a9;
}

.divider-text {
  font-size: 1.2em;
  color: #a9a9a9;
  background-color: transparent;
  padding: 0 10px;
  margin-top: -5px;
}

.feed-post-group{
  margin-top: 0px;
  padding-top: 0px;
}