
/* Private Discord Badge (Top Right) */
.discord-badge {
  position: absolute;
  top: 0px;
  /* Increased to prevent overlap */
  right: 0px;
  /* Slightly adjusted for better spacing */
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  font-weight: 700;
  color: white;
  padding: 6px 12px;
  border-radius: 0px 4px 0px 4px;
      background: linear-gradient(135deg, #f7d04c, #ca8d01,#f7d04c, #ca8d01);
        background-size: 400% 400%;
        animation: gradientAnimation 25s ease-in-out infinite;
  z-index: 10;
  /* Ensures it appears on top */
}

/* Discord Icon */
.discord-badge-icon {
  font-size: 1rem;
  color: white;
}

    /* Center the subscribe button */
    .subscribe-button-container {
      display: flex;
      justify-content: center;
      /* Center horizontally */
      /* Add some space above the button */
    }
  
.subscribe-button {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  color: white;
  margin-bottom: 8px;
  background: linear-gradient(135deg,
      #292929 0%,
      #292929 30%,
      #6c6c6c 50%,
      /* Soft highlight */
      #292929 70%,
      #292929 100%);
  background-size: 400% 400%;
  animation: shimmerEffect 15s infinite linear;
  transition: all 0.1s ease-in-out;
}

/* Smooth Shimmer Animation */
@keyframes shimmerEffect {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

/* Subscribed state */
.subscribe-button.subscribed {
  width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white;
    margin-bottom: 8px;
    background: linear-gradient(135deg,
        #6874fc,
        #4655ff,
        #3747f8,
        #6874fc,
        #4655ff,
        #3747f8);
    background-size: 400% 400%;
    animation: shimmerEffect 30s infinite linear;
    transition: all 0.1s ease-in-out;
}

.follow-button {
  width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white;
    margin-bottom: 8px;
    background: linear-gradient(135deg,
        #414141 0%,
        #414141 30%,
        #6c6c6c 50%,
        /* Soft highlight */
        #414141 70%,
        #414141 100%);
    background-size: 400% 400%;
    animation: shimmerEffect 15s infinite linear;
    transition: all 0.1s ease-in-out;
}

.follow-button:active {

}

/* Subscribed state */
.follow-button.followed {
  width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white;
    margin-bottom: 8px;
      background: linear-gradient(135deg,
          #6874fc,
          #4655ff,
          #3747f8,
          #6874fc,
          #4655ff,
          #3747f8);
              background-size: 400% 400%;
                animation: shimmerEffect 30s infinite linear;
                transition: all 0.1s ease-in-out;
}

.follow-button.followed:active {

}

        /* Daily Post Average Text */
        .daily-post-average {
          font-size: 1.2rem;
          font-weight: 600;
          color: black;
          /* Dark gray for readability */
          text-align: left;
          margin-bottom: 5px;
          margin-top: -10px;
        }

        