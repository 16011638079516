

/* Modal Box (entire popup) */
.modal-box {
  background-color: #fafafa;
  /* Solid white background */
  padding: 30px;
  width: 100%;
  height: 100%;
  /* ✅ Adjust width for better layout */
  z-index: 99999;
  /* ✅ Ensure it's above the navbar */
  text-align: center;
  position: absolute;
  top: 0;
  /* ✅ Moves modal above the navbar */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.google-login-button{
  font-weight: 600;
  border: 0px solid #fff;
}

/* Modal Logo */
.modal-logo {
  width: 25px; /* Adjust size of the logo */
  height: auto;
  margin-top: -7px;
}

/* Close Icon */
.back-icon {
  position: absolute;
    top: 20px;
    left: 30px;
    font-size: 1.2rem;
}

/* Modal Content */
.modal-content {
  margin-top: 30px; /* Ensure content starts below the logo */
  margin-bottom: 20px; /* Space below modal content */
}

/* Modal Header */
.modal-content h2 {
  margin-bottom: 25px;
  font-size: 2em;
  text-align: center;
}

/* Separator for 'or' */
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  font-size: 1rem;
  color: #888;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  height: 2px;
  background: #f1f1f1;
  margin: 0 10px;
  margin-top: 5px;
}

/* Other styles remain the same */


/* Form Styles */
.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form input {
  padding: 10px;
  font-size: 1rem;
border: 0px solid #f1f1f1;
  background-color: #fff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  color: black;
  border-radius: 6px;
  width: 100%;
}

.form-submit-button {
  width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white;
    margin-bottom: 8px;
    background: linear-gradient(135deg,
        #292929 0%,
        #292929 30%,
        #6c6c6c 50%,
        /* Soft highlight */
        #292929 70%,
        #292929 100%);
    background-size: 400% 400%;
    animation: shimmerEffect 15s infinite linear;
    transition: all 0.1s ease-in-out;
}

.form-message {
  margin-top: 10px;
  font-size: 0.9rem;
  color: red;
}

/* Toggle between Login/Register */
.toggle-form-button {
  margin-top: 20px;
  padding: 10px;
  font-size: 1rem;
  color: #555;
  background: none;
  border: none;
  cursor: pointer;
}

/* Error Message */
.error-message {
  color: rgb(236, 0, 0);
  font-size: 0.9rem;
  margin-top: 5px;
  text-align: center;
  width: 100%;
}
.password-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.password-input-container input {
  flex: 1;
  padding-right: 40px; /* Ensure space for the toggle button */
}

.toggle-password-visibility {
  position: absolute;
  right: 10px; /* Adjust as needed */
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1.2rem; /* Adjust size to match input */
  color: #555; /* Neutral color for the icon */
  transition: color 0.3s ease;
}