/* General Container Styling */
.about-me-container {
    padding: 15px;
    background-color: white;
    border: 0px solid #f1f1f1;
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: transform 0.2s ease;
    height: auto;
    /* Auto-adjust height based on content */
    min-height: 50px;
    /* Prevent collapsing */
    overflow: hidden;
    /* Ensure smooth expansion */
    z-index: 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
    margin-bottom: 10px;
}

.about-me-container:hover {
    transform: scale(1.01);
}

/* Buttons Container - Top Right */
.about-buttons {
    position: absolute;
    top: 10px;
    right: 15px;
    display: flex;
    gap: 10px;
    align-items: center;
}

/* Title & Body Fields */
.about-title {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0px;
    margin-top: 0px;
}

/* Bring back correct input styling for title */
.about-me-container #title {
    font-size: 1.8rem;
    font-weight: 600;
    color: black;
    width: 100%;
    border: 2px solid #f1f1f1;
    background-color: transparent;
    border-radius: 6px;
    margin-top: 5px;
    padding: 5px;
}

/* Ensure correct about text styling */
.about {
    font-size: 1.1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    margin-top: -5px;
    color: #555;
    min-height: 20px;
    /* Set a reasonable minimum height */
    height: auto;
    /* Auto height */
    resize: vertical;
    /* Allow vertical resizing */
}

/* Auto-expanding Textarea */
.about-textarea {
    font-size: 1.1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    color: #555;
    width: 100%;
    border: 2px solid #f1f1f1;
    background-color: transparent;
    border-radius: 6px;
    resize: none;
    /* Prevent manual resizing */
    overflow-y: hidden;
    /* Hide scrollbar */
    min-height: 50px;
    /* Minimum height */
    margin-top: 20px;
    padding: 5px;
}

/* Ensures textarea expands when needed */
.about-textarea:focus {
    outline: none;
}
/* Status Row */
.status-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscribers {
font-size: 1.2rem;
    font-weight: 600;
    color: #a9a9a9;
    margin-bottom: 0px;
}

.followers {
    font-size: 1.2rem;
    font-weight: 600;
    color: #a9a9a9;
    margin-bottom: 0px;
}

/* Input Container - More Space */
.about-form {
    margin-top: 40px;
}

/* Edit Icon */
.edit-icon-about {
    margin-top: 5px;
    font-size: 1.4rem !important;
    color: black;
    cursor: pointer;
}

/* Save & Cancel Buttons */
.about-save-button,
.about-cancel-button {
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    padding: 3px;
    margin-top: 5px;
}

.about-save-button {
    background: linear-gradient(135deg,
                #6874fc,
                #4655ff,
                #3747f8,
                #6874fc,
                #4655ff,
                #3747f8);
        background-size: 999% 999%;
        animation: gradientAnimation 30s ease-in-out infinite;
    color: white;
    padding: 8px 18px;
}

.about-cancel-button {
    color: #555;
    background-color: white;
}

/* Input Containers */
.about-input-container {
    position: relative;
    width: 100%;
}

.about-textarea-container {
    position: relative;
    width: 100%;
}

