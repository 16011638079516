html,
body {
  height: 100%;
  overflow-y: visible !important;
  /* ✅ Ensures sticky works */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: visible !important;
    background-color: #fafafa;
  /* ✅ Prevents hidden sticky */
}

.main-content {
  background-color: #fafafa;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  /* ✅ Pushes content down */
  padding-bottom: 100px;
  overflow: visible !important;
  /* ✅ Fixes sticky navbar */
  max-width: 800px;
}

/* Shared card styles */
.card {
  border-radius: 6px;
  padding: 0;
  /* Remove padding to allow banner to extend to edges */
  background-color: #ffffff;
  margin-bottom: 10px;
  transition: transform 0.2s ease;
  border: 0px solid #f1f1f1 !important;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
}

.card:hover {
  transform: scale(1.01);
}

/* Add padding inside cards */
.card-margin {
  padding: 10px;
}

/* Shared container styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

/* Footer styling - No longer blocks scrolling */
.footer {
  background-color: #fafafa;
  color: #a9a9a9;
  text-align: center;
  padding: 20px;
  width: 100%;
  padding-bottom: 70px;
  position: relative;
  /* ✅ Prevents interfering with sticky navbar */
}

/* Divider styling */
.universal-divider-line {
  border: none;
  border-bottom: 2px solid #adadad;
  margin-top: 0px;
}

.universal-divider-line-margin {
  border: none;
  border-bottom: 2px solid #adadad;
  margin: 10px;
  margin-top: 0px;
}

/* Button styling */
.button-group {
  position: absolute;
  top: 80px;
  right: 0px;
  cursor: pointer;
  padding: 15px;
  color: black;
}

.universal-save-button,
.universal-cancel-button {
  font-size: 1rem !important;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  padding: 10px 16px !important;
}

.universal-save-button {
    background: linear-gradient(135deg,
        #6874fc,
        #4655ff,
        #3747f8,
        #6874fc,
        #4655ff,
        #3747f8);
    background-size: 999% 999%;
    animation: gradientAnimation 30s ease-in-out infinite;
    color: white;
    font-weight: 600;
    border: none;
    padding: 8px 15px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
}

.universal-cancel-button {
  color: #555;
  background-color: transparent;
}

/* ✅ Disable background scrolling when post is open */
body.modal-open {
  overflow: hidden;
  height: 100vh;
  position: fixed;
  width: 100%;
}