/* Shared banner styles */
.banner {
  width: 100%;
  position: relative;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
  z-index: 1;
}

.banner-image {
  width: 100%;
  height: 80px;
  object-fit: cover;
  display: block;
  border-radius: inherit;
}

/* Profile picture styles */
.profile-picture-container {
  position: relative;
  width: 90px;
  height: 90px;
  margin-top: -50px;
  margin-left: 10px;
  border: 6px solid white;
  border-radius: 50%;
  z-index: 2;
}

.profile-picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* Editable overlay styles */
.editable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.profile-picture-overlay {
  border-radius: 50%;
}

.banner-overlay {
  border-radius: 5px, 5px, 0px, 0px;
}

.is-editing .editable-overlay {
  opacity: 1;
}

/* Text styles */
.username {
  font-size: 1.2rem;
  font-weight: 600;
  color: #a9a9a9;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.display-name {
  font-size: 1.8rem;
  font-weight: 600;
  margin-left: 15px;
  padding: 0px;
  margin-top: 0px;
}

/* Input container for title */
.input-container {
  position: relative;
  width: calc(100% - 30px);
  margin-left: 15px;
}

/* Input container for bio */
.textarea-container {
  position: relative;
  width: calc(100% - 30px);
  margin-left: 15px;
}

/* Character counter inside input boxes */
.char-count {
  font-size: 0.8rem;
  color: #777;
  position: absolute;
  bottom: -10px;
  /* Move up slightly */
  right: 0px;
  background: transparent;
  padding: 1px 5px;
  border-radius: 3px;
  pointer-events: none;
  line-height: 1;
}

.title-char-count {
  font-size: 0.8rem;
  color: #777;
  position: absolute;
  bottom: -15px;
  /* Move up slightly */
  right: 0px;
  background: transparent;
  padding: 1px 5px;
  border-radius: 3px;
  pointer-events: none;
  line-height: 1;
}

.display-name-edit {
  font-size: 2rem;
  font-weight: 600;
  /* Space for character counter */
border: 2px solid #f1f1f1;
  background-color: transparent;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  margin-top: 10px;
}

/* Styling for the bio text area */
.bio {
    font-size: 1.1rem;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      margin-top: -5px;
      color: #555;
      min-height: 20px;
      /* Set a reasonable minimum height */
      height: auto;
      /* Auto height */
      resize: vertical;
      /* Allow vertical resizing */
  margin-top: -15px;
  margin-left: 15px;
  margin-right: 15px;
}

.bio-edit {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.5;
  width: 100%;
  /* Ensure full width */
  height: auto;
border: 2px solid #f1f1f1;
  background-color: transparent;
  border-radius: 6px;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
  margin-top: 20px;
  /* Space for character counter */
  padding: 5px;
  margin-bottom: 5px;
}

.textarea-container {
  position: relative;
  width: calc(100% - 30px);
  /* Match display-name-edit width */
  margin-left: 15px;
}

/* Large edit icon */
.edit-icon-large {
  position: absolute;
  top: 95px;
  right: 0px;
  font-size: 1.4rem;
  color: black;
  margin-right: 15px;
}

/* Parent container for the selected leagues in saved mode */
.selected-leagues {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  justify-content: flex-start;
  padding-left: 15px;
  padding-bottom: 15px;
}

.edit-icon {
  color: white;
  font-size: 1.5rem;
}

/* Default league button */
.league-button {
  font-size: 1rem;
  background-color: #f1f1f1;
  padding: 4px;
  border-radius: 6px;
  white-space: nowrap;
  font-weight: 600;
  color: black;
  border: none;
}

.show-more-btn {
  background: none;
  border: none;
  padding: 0;
  color: #5865F2;
  text-decoration: none;
  cursor: pointer;
  font-size: inherit;
}