.navbar {
  position: sticky !important;
  /* ✅ Fixes navbar not sticking */
  top: 0;
  width: 100%;
  z-index: 100;
  background: linear-gradient(rgba(250, 250, 250, 1), rgba(250, 250, 250, 0.85));
  backdrop-filter: blur(9px);
  transition: all 0.3s ease;
  padding: 15px;
  transition: padding 0.3s ease, transform 0.3s ease;
  
}

.navbar.navbar-scrolled{
  padding-bottom: 3px;
  padding-top: 3px;
}

.navbar .navbar-logo .navbar-logo-img {
  height: 2rem;
  transition: scale 0.3s ease, transform 0.3s ease;

}

.navbar.navbar-scrolled .navbar-logo .navbar-logo-img {
  transform: scale(0.9);
}
/* Transparent Navbar when Logged Out on Landing Page */
.transparent-navbar {
  background: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: none;
  padding: 20px 15px;
}

/* Navbar left section (logo and brand) */
.navbar-left {
  display: flex;
  align-items: center; /* Align logo vertically */
  margin-left: 10px; /* Space between logo and username */
}
/* Adjust spacing for unscrolled state */
.nav-links {
  display: flex;
  align-items: right;
  list-style: none;
  gap: 25px; /* Tight spacing when at the top */
  margin: 0;
  margin-right: 15px;
  padding: 0;
  transition: gap 0.2s ease;
}

.nav-links a {
  text-decoration: none;
  color: #a9a9a9;
}

/* Non-selected icons in sidebar remain grey */
.sidebar-menu {
    padding: 0;
      /* Removes default padding */
  list-style: none;
    /* Removes bullet points */
    margin-top: 20px;
}

.sidebar-item {
  font-weight: normal;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 15px;
  text-decoration: none;
  color: #a9a9a9;
  margin-left: -20px;
  width: 100%;
}

.sidebar-text {
  font-size: 1.3rem;
  /* Matches the sidebar text size */
}

/* Dark mode button */
.darkmode-link {
  color: #1c0657;
  /* ✅ Border between Dark Mode & Logout */
  padding-bottom: 10px;
  /* ✅ Adds space below Dark Mode */
}

.darkmode-icon {
  margin-right: 10px;
  /* Add spacing between the icon and text */
  font-size: 1.3rem;
  color: #1c0657;
}

/* Logout button */
.logout-link {
  font-size: 1.3rem;
  color: rgb(236, 0, 0);
  font-weight: normal;
}

.sidebar-bottom {
  padding-top: 10px;
  border-top: 2px solid #f1f1f1;
  /* Light gray border */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* ✅ Centers all text & icons */
  width: 100%;
}
.logout-icon {
  margin-right: 10px; /* Add spacing between the icon and text */
  font-size: 1.2rem;
  color: rgb(236, 0, 0);
}

/* Sidebar icons default to gray */
.side-icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #a9a9a9;
  transition: color 0.3s ease;
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0px;
  right: -220px; /* Initially hidden off-screen */
  width: 220px; /* Adjust width as needed */
  height: calc(100% - 50px); /* Full height */
  background-color: #fafafa;
    backdrop-filter: blur(9px);
  transition: right 0.2s ease; /* Smooth slide-in transition */
  z-index: 60; /* Above the overlay */
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 30px;
}

.sidebar.open {
  right: 0; /* Slide in from the right */
}

/* ✅ Sidebar icons turn black when selected */
.sidebar-item.active .side-icon {
  color: black;
}

/* ✅ Sidebar text turns black when selected */
.sidebar-item.active {
  color: black;
}

/* Login and Signup Buttons */
.nav-login-button {
  background-color: transparent;
    color: black;
    font-weight: 600;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 5px;
    margin-top: 5px;
      transition: height 0.3s ease, transform 0.3s ease;
}

.nav-signup-button {
    background: linear-gradient(135deg,
          #6874fc,
          #4655ff,
          #3747f8,
          #6874fc,
          #4655ff,
          #3747f8);
      background-size: 999% 999%;
      animation: gradientAnimation 30s ease-in-out infinite;
  color: white;
  font-weight: 600;
  border: none;
  padding: 8px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: -20px;
  margin-bottom: 5px;
  margin-top: 5px;
  transition: height 0.3s ease, transform 0.3s ease;
  transition: margin 0.3s ease, transform 0.3s ease;
}

.navbar.navbar-scrolled .nav-signup-button{
  transform: scale(0.9);
  margin-left: -30px;
}

.navbar.navbar-scrolled .nav-login-button {
  transform: scale(0.9);
}

.transparent-navbar .nav-login-button {
  background-color: transparent;
  color: white;
  font-weight: 600;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: -20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.transparent-navbar .nav-signup-button {
  background-color: hsla(235, 98%, 24%, 0.3);
  color: white;
  border: none;
  font-weight: 600;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: -20px;
  margin-bottom: 5px;
  margin-top: 5px;
}


/* Hide icons on mobile while keeping Login and Signup buttons visible */
@media (max-width: 768px) {
  .nav-icon-item {
    display: none;
    /* Hide all icons on mobile */
  }

}

/* Bottom Navbar Styling */
.bottom-navbar {
  display: none;
  /* Hidden by default */
}

@media (max-width: 768px) {
  .bottom-navbar {
    display: flex;
    /* Show on mobile screens */
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.85), rgb(255, 255, 255));
    backdrop-filter: blur(9px);
    /* Apply blur effect */
    padding: 10px 0;
    z-index: 1100;
    padding-bottom: 30px;
    padding-top: 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  }

  .bottom-navbar .nav-icon {
    font-size: 1.6rem;
    color: #a9a9a9;
  }

    .bottom-navbar .nav-icon.active {
      color: black;
      /* Black color for the active button */
    }

}

    .bottom-navbar .nav-profile-picture.active {
      border: 2px solid black;
    }

.post-icon{
    font-size: 1.6rem;
      color: #a9a9a9;
}

.post-icon.active {
        color: rgb(88, 101, 242);
    transition: background-color 0.4s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
}

.sidebar-toggle-icon{
      display: flex;
        align-items: center;
        /* Vertically align icon */
        justify-content: center;
        /* Horizontally center icon */
        cursor: pointer;
        /* Ensure it's clickable */
        flex-shrink: 0;
        /* Prevent shrinking */
        font-size: 2rem;
        color: black;
        transition: scale 0.3s ease, transform 0.3s ease;
}

.navbar.navbar-scrolled .sidebar-toggle-icon {
  transform: scale(0.9);
}


/* Left-side overlay that moves with the sidebar */
.sidebar-left-overlay {
  position: fixed;
  top: 0;
  right: 220px;
  /* Start aligned with the sidebar */
  width: calc(100% - 220px);
  /* Covers the left side of the screen */
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.0));
  z-index: 50;
  /* Below sidebar, above content */
  opacity: 0;
  pointer-events: none;
  /* ✅ Prevents blocking clicks when closed */
  transform: translateX(200%);
  /* Starts off-screen */
  transition: transform 0.3s ease, opacity 0.6s ease;
}

/* When sidebar opens, move the overlay into view */
.sidebar.open~.sidebar-left-overlay {
  transform: translateX(0);
  opacity: 1;
  backdrop-filter: blur(2px);
  pointer-events: auto;
  /* ✅ Now allows clicking the overlay to close sidebar */
}


