@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

/* Gradient Header Section */
.landing-header-section {
  position: relative;
  text-align: left;
  color: white;
  padding: 80px 20px;
    margin-top: -65px;

  /* Animated Gradient */
    background: linear-gradient(135deg,
      #9aa3ff,
        #5865F2,
        #3747f8,
        #5865F2,
        #9aa3ff,
        #5865F2,
        #3747f8);
      background-size: 999% 999%;
      animation: gradientAnimation 30s ease-in-out infinite;
}

/* Angled White Separator */
.landing-header-section::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fafafa;
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg"><path fill="%23fafafa" d="M0,224L48,229.3C96,235,192,245,288,240C384,235,480,213,576,192C672,171,768,149,864,165.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>');
  background-repeat: no-repeat;
  background-size: cover;
}

/* Text Styling */
.landing-header {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.landing-subheader {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 auto;
}

/* White Content Section Below */
.landing-content-section {
  background: #fafafa;
  text-align: left;
  padding: 100px 20px;
  color: #333;
}

/* Buttons */
.landing-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.landing-button {
  background: #0066ff;
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.landing-button:hover {
  background: #004ccc;
}