.reaction-bar-container {
    display: flex;
    justify-content: flex-end;
    /* Push content to the right */
    align-items: center;
    width: 100%;
    height: 25px;
    background-color: transparent;
    margin-top: 5px;
    margin-bottom: -3px;
    padding: 0px;
    padding-top: 5px;
    gap: 20px;
    /* Adds spacing between elements */
}

.status-text {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: normal;
    gap: 2px;
}

.status-text span {
    color: black;
        font-weight: normal;
    /* Makes "live" text black */
}

.status-text .settled {
    color: #a9a9a9;
    /* Keeps "Settled" gray */
}

.live-text {
    color: #a9a9a9 !important;
}


/* Icon styling - Same size as text */
.status-icon {
    font-size: .5rem;
    /* Match text size */
    vertical-align: middle;
    padding: .2rem;
    border-radius: 50%;

}


/* Only color the icon */
.status-icon.live {
    /* color: rgb(255, 213, 0); */
    color:#a9a9a9;
        font-size: 1rem;
    /* Red for live */
}

.status-icon.settled {
    color: #a9a9a9;
    font-size: 1rem;
    /* Green for Settled */
}

/* Reaction totals */
.reaction-totals {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1rem;
    font-weight: normal;
}

/* Default unreacted state */
.reaction-totals.unreacted .reaction-icon,
.reaction-totals.unreacted .total-play {
    color: #a9a9a9;
    transition: color 0.3s ease-in-out;
}

/* Ensures all icons and text are aligned */
.reaction-icon {
    font-size: 1rem;
    vertical-align: middle;
}

.total-play {
    font-size: 1rem;
    font-weight: normal;
    margin-top: -1px;
}

/* Slower Spinning Animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Plain purple color for reacted state */
.reaction-totals.reacted .reaction-icon,
.reaction-totals.settled-tailed .reaction-icon {
    color: #3747f8;
    /* Solid purple */
}

.reaction-totals.reacted .total-play {
    color: #3747f8;
    /* Solid purple text */
}

.reaction-totals.settled-tailed .total-play {
    /* color: #00c711; */
    color: #3747f8;
    transition: color 0.3s ease-in-out;
}