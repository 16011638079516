/* ✅ Button stays on the right above the feed */
.create-alert-button {
  width: calc(100% - 30px);
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    color: white;
    background: linear-gradient(135deg,
        #6874fc,
        #4655ff,
        #3747f8,
        #6874fc,
        #4655ff,
        #3747f8);
    background-size: 400% 400%;
    animation: shimmerEffect 30s infinite linear;
    transition: all 0.3s ease-in-out;
    margin: 15px;
}

/* Modal Overlay */
.alert-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  /* Black with 10% opacity */
  backdrop-filter: blur(2px);
  z-index: 50;
}

.new-alert-icon {
  margin-right: 5px;
}

/* ✅ Centered Modal Window */
.create-alert-modal {
  background: white;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  width: calc(100% - 30px);
  height: 550px;
}

/* ✅ Close Button */
.close-alert-icon {
  position: absolute;
  font-size: 1.5rem;
  top: 0px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.create-alert-button-text {
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 4px solid #5865F2;
  padding-right: calc(100% - 147px);
  padding-bottom: 10px;
}

.create-alert-submit-button {
  position: absolute;
    bottom: 30px;
    left: 0%;
    width: calc(100% - 30px);
    height: 50px;
    /* Same height for consistency */
    border: 0px solid white;
    color: white;
    padding: 10px 20px;
    margin: 15px;
    cursor: pointer;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: 600;
    transition: background-color 0.3s;
    background: linear-gradient(135deg, #121212, #121212, #121212, #121212, #3d3d3d, #121212, #121212, #121212, #121212);
    background-size: 999% 999%;
    animation: gradientAnimation 30s ease-in-out infinite;
}

/* Tab Menu */
.create-alert-tab-menu {
  display: flex;
    justify-content: left;
    gap: 5px;
    margin-bottom: 10px;
    border-bottom: 2px solid #f7f7f7;
    padding-left: 0px;
    padding-top: 20px;
}

.create-alert-tab {
  flex-shrink: 0;
    border: 0;
    background-color: transparent;
    color: #555;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    font-size: 1.2rem;
    padding-left: 10px;
}

.create-alert-tab.active {
  background: transparent;
    border: 0px solid #f7f7f7;
    color: black;
    font-weight: 600;
}

/* Input Fields */
.create-alert-container #title {
  font-size: 1.5rem;
  color: black;
  font-weight: normal;
  padding: 5px;
  border-radius: 6px;
  width: 100%;
  border: 2px solid #f1f1f1;
  margin-top: 50px;

}

.create-alert-container #body {
  font-size: 1rem;
  color: black;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  border-radius: 6px;
  width: 100%;
  min-height: 100px;
  border: 2px solid #f1f1f1;
  padding: 5px;
}