/* Kappers container */
.kappers-container {
  margin: 20px auto;
  padding: 10px;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5px;
  margin-top: -15px;
}

.search-bar-container:hover {
  transform: scale(1.01);
  /* Slightly increase the size */

}

.search-bar-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border: 0px solid #f1f1f1;
  border-radius: 50px;
  padding: 8px;
  padding-left: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  
}

.search-icon {
  font-size: 1.2rem;
  color: black;
  margin-right: 8px;
}

.search-bar {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  background: transparent;
  color: #a9a9a9;
  height: 100%;
}

/* Kapper grid layout */
.kappers-grid {
  display: flex;
  flex-direction: column;
}

.kapper-card {
  position: relative;
  /* Allow pseudo-element positioning */
  border-radius: 6px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* Change to column layout */
  cursor: pointer;
  margin-bottom: 10px;
  /* Prevent the pseudo-element from overflowing the card */
  border: solid 0px #f1f1f1;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
}

.kapper-card::before {
  content: '';
  /* Required for pseudo-element */
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 5px;
  width: 4px;
  /* Adjust the width of the gradient border */
  background:  #f1f1f1;
  /* Blue to purple gradient */
  border-radius: 2px;
}

.kapper-card.pro-card::before {
      background: linear-gradient(135deg,
            #6874fc,
            #4655ff,
            #3747f8,
            #6874fc,
            #4655ff,
            #3747f8);
        background-size: 999% 999%;
        animation: gradientAnimation 30s ease-in-out infinite;
  /* Blue to purple gradient */
}

.kapper-card:hover {
  transform: scale(1.01);
}

/* Profile picture and username container */
.kapper-info {
  display: flex; /* Align profile picture and username on the same row */
  align-items: center; /* Vertically center the profile picture with the username */
  gap: 10px; /* Space between profile picture and username */
  margin-bottom: 5px; /* Space between username and leagues */
}

/* Profile picture */
.kapper-profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -5px;
}

/* Kapper username */
.kapper-display-name {
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 0px; /* Remove the default margin */
}

/* Kapper leagues */
.kapper-leagues {
  display: flex; /* Use flexbox to display the leagues in one row */
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 5px; /* Add space between league items */
  justify-content: flex-start; /* Align leagues to the left */
}

.kapper-league {
    font-size: 1rem;
      background-color: #f1f1f1;
      padding: 4px;
      border-radius: 6px;
      white-space: nowrap;
          font-weight: 600;
}


.no-leagues {
  color: #999;
  font-style: italic;
}

.league-filters {
  display: flex;
  gap: 5px;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent buttons from wrapping */
  scrollbar-width: none;
  /* For Firefox */
  margin-bottom: 10px;
  margin-top: 10px;
}

.league-filter-button {
  flex-shrink: 0;
  border: 0px;
  background-color: transparent;
  color: #555;
  font-size: 1rem;
border: 0px solid #f1f1f1;
  background-color: #f1f1f1;
  border-radius: 6px;
  margin: 1px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
  font-weight: normal;
}

.league-filter-button.active {
background-color: #d5d5d5;
      border: 0px solid #4655ff;
  color: black;
  font-weight: 600;
}

.league-filters::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Edge */
}

.badge-icon-small {
  font-size: .9rem;
  color: white;
  /* Add spacing between the username and the icon */
  position: relative;
  /* To enable top adjustment */
  top: -5px;
  /* Move the icon up by 10px */
}